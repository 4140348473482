
.logoContainer {
  display: flex;
  justify-content: center;
  /* margin-bottom: 1rem; */
}

.logo {
  max-width: 50%;
  height: auto;
}

.designBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 1rem;
}

/* .designImage {
  max-width: 100%;
  height: auto;
  margin-bottom: 0.5rem;
} */

.designImage {
  max-width: 100%;
  /* height: auto; */
  margin-bottom: 10px;
  min-height: 200px; /* Set an initial maximum height */
}

/* Media queries for larger screens */
@media (min-width: 480px) {
  .designImage {
    min-height: 100px; /* Increase the maximum height for screens 480px and wider */
  }
}

@media (min-width: 768px) {
  .designImage {
    min-height: 150px; /* Increase the maximum height for screens 768px and wider */
  }
}

@media (min-width: 992px) {
  .designImage {
    min-height: 200px; /* Increase the maximum height for screens 992px and wider */
  }
}

.designText {
  margin: 0;
}

.container {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  min-height: 75vh;
  /* position: relative; */
  overflow: hidden;
}

.contentContainer {
  position: flex;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  padding: 20px;
  text-align: center;
  color: black;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
}

.heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0;
}

.subheading {
  font-size: 36px;
  font-weight: bold;
  margin-top: 0;
  color: #ff69b4;
}

.description {
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: bolder;
}

.searchContainer {
  position: relative;
  display: inline-block;
  width: 80%;
  padding: 20px;
  background-color: rgb(255, 218, 225);
  border-radius: 20px;
  margin: 20px;
  margin-bottom: 0px;
}

.searchContainer p{
  text-align: left;
  margin-bottom: 0px;
}

.findDessert {
  position: absolute;
  top: -1.5rem;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  padding: 0;
}

.findDessert {
  font-size: 18px;
  margin-bottom: 5px;
}

.topbarSearchWithLeftPadding {
  padding-left: 24px;
  /* height: var(--topbarHeightDesktop); */
  height: 50px;
  text-align: center;
  @media (--viewportLarge) {
    padding-left: 34px;
  }
}

.searchInput {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.designContainer {
  display: flex;
  justify-content: center;
  /* flex-wrap: wrap; */
  margin-bottom: 2rem;
}

.designBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
  text-align: center;
}

.designImage {
  max-width: 100%;
  height: auto;
  margin-bottom: 0.5rem;
}

.designText {
  font-size: 14px;
  margin-top: 5px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  width: 400px;
}

.button:hover {
  background-color: rgb(255,218,225);
}

.button {
  padding: 10px 5px;
  background-color: rgb(243,68,94);
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden; /* This will prevent the text from overflowing the button */
  text-overflow: ellipsis; /* This will add an ellipsis (...) when the text is truncated */
  display: inline-block; /* This will make the buttons equal in size */
  min-width: 120px; /* Set a fixed width for the buttons */
  text-align: center; /* This will center the text within the button */
  font-size: 2rem;
}

/* Media queries for responsive button sizes */
@media (min-width: 768px) {
  .button {
    font-size: 2.5rem;
    min-width: 300px;
  }
}

@media (max-width: 768px) and (min-width: 480px) {
  .button {
    font-size: 1.2rem;
    min-width: 200px;
  }
}

@media (max-width: 480px) {
  .button {
    font-size: 1.0rem;
  }
}

.browseButton {
  padding: 10px 5px;
  background-color: rgb(255,218,225);
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  white-space: nowrap; /* This will prevent the text from wrapping */
  font-size: 1.5rem;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .browseButton {
    font-size: 1.0rem;
  }
}

@media (max-width: 480px) {
  .browseButton {
    font-size: 0.8rem;
  }
}
.animatedLink {
  display: inline-block;
  padding: 15px 25px;
  font-size: 18px;
  color: white;
  text-decoration: none;
  background-color: #007BFF;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  transition: color 0.3s ease;
}

.animatedLink::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, #ff0077, #007BFF, #00ff99);
  transition: all 0.4s;
  z-index: -1;
  animation: animateBackground 3s linear infinite;
}

.animatedLink:hover {
  color: #007BFF;
}

.animatedLink:hover::before {
  left: 0;
}

@keyframes animateBackground {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}