.carousel {
  width: 100%;
  margin-top: 10px;
  display: block;
  text-align: center;
  position: static !important;
  align-items:center;
  overflow: hidden;

}
.carousel ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  position: relative;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  will-change: transform,transition

}
.carousel ul li{
  flex: 1 1 auto;
    position: relative;
    width: 483px;
    transform-style: preserve-3d;
    backface-visibility: hidden;
}

.contentStyle {
  margin: 0;

  width: 100%;
  color: black !important;
  line-height: 160px;
  text-align: center;
  background-color: var(--marketplaceColorLight);
  border-radius: 10px;
}

button {

  /* color: rgb(0, 0, 0) !important;
  background-color: #ff000000; */
}


.videoReview {
width: 350px;
  -webkit-mask-image: url("../../assets/Videos/svg.mask-shape-2.svg");
  mask-image:  url("../../assets/Videos/svg.mask-shape-2.svg");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

.playButton {
  min-width: 70px !important;
  min-height: 70px;
  left: 31%;
  position: absolute;
  top: 20%;
  background-color: var(--marketplaceColorLight);
}
.playButton:hover{
  background-color: var(--marketplaceColor);
}
.buttonIcon {

  color:gray;
font-size: 100;
}


@media (max-width: 768px) {

  .carousel {
   height: 300px;
   width: 300px;
   display: flex;

  }




  .videoReview {
    width: 70%;
  }

  .playButton {
    margin-bottom:-14px;
    min-width: 60px !important;
    min-height: 60px;
    left: 17%;
    position: absolute;
    top: 12%;
    background-color: var(--marketplaceColorLight);
  }

}

